import React, { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import UploadContextType from '../types/UploadContextType'

const initialState: UploadContextType = null

const UploadContext = createContext(initialState)

const UploadProvider = ({ children }) => {
  const [uploadErrorCode, setUploadErrorCode] = useState<string | null>(null)
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(
    null,
  )

  const setUploadError = (errorCode: string, errorMessage?: string) => {
    setUploadErrorCode(errorCode)
    setUploadErrorMessage(errorMessage)
    console.log('IN THE CONTEXT: ', errorCode, errorMessage)
  }

  // Use useMemo to memoize the context value
  const contextValue = useMemo(
    () => ({
      uploadErrorCode,
      uploadErrorMessage,
      setUploadError,
    }),
    [uploadErrorCode, uploadErrorMessage],
  )

  return (
    <UploadContext.Provider value={contextValue}>
      {children}
    </UploadContext.Provider>
  )
}

UploadProvider.propTypes = {
  children: PropTypes.node.isRequired, // Add prop type validation for children
}

export { UploadContext, UploadProvider }
