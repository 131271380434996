import React, { useContext } from 'react'
import CustomAlert from '../Alert/CustomAlert'
import { UploadContext } from '../../contexts/UploadContext'

interface ErrorMessageBoxProps {
  style?: React.CSSProperties // Make the style prop optional
}

const ErrorMessageBox: React.FC<ErrorMessageBoxProps> = ({ style }) => {
  const { uploadErrorCode, uploadErrorMessage } = useContext(UploadContext)

  return (
    <div style={style}>
      {uploadErrorCode === 'FILE_UPLOAD_SUCCESS' && (
        <CustomAlert
          severity="success"
          title="File Uploaded Successfully"
          shouldRender={!!uploadErrorCode}
        ></CustomAlert>
      )}
      {uploadErrorCode === 'FILE_UPLOAD_ERROR' && (
        <CustomAlert
          severity="error"
          title="File upload failed. Click here to check errors."
          dialogTitle="File Upload Errors"
          dialogContent={uploadErrorMessage}
          shouldRender={!!uploadErrorCode}
        ></CustomAlert>
      )}
    </div>
  )
}

export default ErrorMessageBox
