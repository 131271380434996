import React, { useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Stack, Box, Paper, Divider, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import RecentUpdate from '../types/RecentUpdate'
import ShippingStatus, { StatusUpdates } from '../types/ShippingStatus'
import SearchRow from '../components/SearchRow'
import TableauChartPage from '../components/TableauChartPage'
import { UploadProvider } from '../contexts/UploadContext'
import ErrorMessageBox from '../components/ErrorMessageBox/ErrorMessageBox'

// const SearchBar = styled(TextField)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   color: theme.palette.text.secondary,
// }))

const Update = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const HomePageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const Overview = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 800,
  height: 600,
}))

const exampleUpdates: RecentUpdate[] = [
  // {
  //   sku: 'ABC123',
  //   quantity: 1050,
  //   container: 'ZGB240',
  //   status: ShippingStatus.AT_DOMESTIC_PORT,
  //   time: new Date(2022, 9, 23, 12, 24, 56),
  // },
  // {
  //   sku: 'XYZ789',
  //   quantity: 640,
  //   container: 'MSG621',
  //   status: ShippingStatus.ON_FREIGHT,
  //   time: new Date(2022, 9, 5, 17, 32, 45),
  // },
  // {
  //   sku: 'GER935',
  //   quantity: 350,
  //   status: ShippingStatus.ON_TRUCK,
  //   time: new Date(2022, 7, 12, 20, 36, 56),
  // },
  // {
  //   sku: 'ABC123',
  //   quantity: 1050,
  //   container: 'ZGB240',
  //   status: ShippingStatus.OVERSEAS,
  //   time: new Date(2022, 6, 7, 56, 10, 13),
  // },
]

const createRecentUpdate = (update: RecentUpdate): JSX.Element => (
  <Update>
    <p>
      {`${update.quantity} of ${update.sku} ${StatusUpdates.get(
        update.status,
      )}${update.container ? `Container ${update.container}` : ''}`}
    </p>
  </Update>
)

if (typeof window !== 'undefined') {
  // console.log('You are on the browser,You are good to go')
}

const Home: React.FC = () => {
  // const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   // keycode for "enter" key
  //   if (e.key === 'Enter') {
  //     e.preventDefault()
  //     router.push(onEnterRoute)
  //   }
  // }

  // const onChange = (e) => {
  //   setSkuID(e.target.value)
  // }

  // const onChangePO = (e) => {
  //   setPoID(e.target.value)
  // }

  // TODO: change to be flexbox centered
  return (
    <UploadProvider>
      <Container maxWidth={false} fixed sx={{ marginTop: 10 }}>
        <Head>
          <title>Speedway Motors International Shipment Tracking</title>
          <meta name="description" content="International Shipment Tracking" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <div
          style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}
        >
          <SearchRow initialPO="" initialSKU="" intialContainer="" />
          <ErrorMessageBox style={{ marginTop: '1%' }}></ErrorMessageBox>
        </div>
        <Stack direction="row" spacing={4}>
          {/* <HomePageContainer>
          <h1>Overview</h1>
          <Overview>
             <TableauChartPage /> 
          </Overview>
        </HomePageContainer>
        <HomePageContainer>
          <h1>Recent Updates</h1>
          <Stack spacing={2} divider={<Divider />}>
            {exampleUpdates.map((update) => createRecentUpdate(update))}
          </Stack>
        </HomePageContainer>
        */}
        </Stack>
      </Container>
    </UploadProvider>
  )
}

export default Home
function useEffect(arg0: () => void, arg1: undefined[]) {
  throw new Error('Function not implemented.')
}
