import React, { useState } from 'react'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CustomDialog from '../Dialog/CustomDialog'

interface CustomAlertProps {
  severity: 'info' | 'success' | 'warning' | 'error'
  title?: string
  children?: React.ReactNode
  customStyle?: React.CSSProperties
  dialogTitle?: string
  dialogContent?: string
  shouldRender: boolean
}

function CustomAlert({
  severity,
  title,
  children,
  customStyle,
  dialogTitle,
  dialogContent,
  shouldRender,
}: CustomAlertProps) {
  const [isOpen, setIsOpen] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleAlertClick = () => {
    if (severity === 'error') {
      setIsDialogOpen(true) // Open the dialog when the severity is "error"
    }
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  if (!shouldRender || !isOpen) {
    return null
  }
  return (
    <div>
      {isDialogOpen && (
        <CustomDialog
          open={isDialogOpen}
          onClose={closeDialog}
          title={dialogTitle}
          content={dialogContent}
        />
      )}
      <Alert
        severity={severity}
        style={{ position: 'relative', ...customStyle }}
        onClick={handleAlertClick}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
          style={{ position: 'absolute', top: '8px', right: '8px' }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Alert>
    </div>
  )
}

export default CustomAlert
