import { Color } from '@mui/material'
import { red, orange, yellow, green, blue, purple } from '@mui/material/colors'

enum ShippingStatus {
  INVOICED,
  AT_INTERNATIONAL_PORT,
  OVERSEAS,
  AT_DOMESTIC_PORT,
  ON_FREIGHT,
  ON_TRUCK,
  ARRIVED,
  DEFAULT,
}

export const StatusUpdates = new Map<ShippingStatus, string>([
  [ShippingStatus.INVOICED, ' have been invoiced'],
  [
    ShippingStatus.AT_INTERNATIONAL_PORT,
    ' have arrived at an international port',
  ],
  [ShippingStatus.OVERSEAS, ' have departed from an international port on '],
  [ShippingStatus.AT_DOMESTIC_PORT, ' have arrived at a domestic port on '],
  [ShippingStatus.ON_FREIGHT, ' have been transferred to freight on '],
  [ShippingStatus.ON_TRUCK, ' have been transferred to a truck'],
  [ShippingStatus.ARRIVED, ' have arrived at Speedway'],
])

export const GenericStatusUpdates = new Map<ShippingStatus, string>([
  [ShippingStatus.INVOICED, 'Invoiced'],
  [ShippingStatus.AT_INTERNATIONAL_PORT, 'At international port'],
  [ShippingStatus.OVERSEAS, 'Departed from international port'],
  [ShippingStatus.AT_DOMESTIC_PORT, 'Arrived at domestic port'],
  [ShippingStatus.ON_FREIGHT, 'Transferred to freight'],
  [ShippingStatus.ON_TRUCK, 'Transferred to truck'],
  [ShippingStatus.ARRIVED, 'Arrived at Speedway'],
  [ShippingStatus.DEFAULT, 'Placeholder Status'],
])

export const StatusColors = new Map<ShippingStatus, string>([
  [ShippingStatus.INVOICED, red[900]],
  [ShippingStatus.AT_INTERNATIONAL_PORT, orange[900]],
  [ShippingStatus.OVERSEAS, yellow[700]],
  [ShippingStatus.AT_DOMESTIC_PORT, blue[500]],
  [ShippingStatus.ON_FREIGHT, purple[700]],
  [ShippingStatus.ON_TRUCK, purple[300]],
  [ShippingStatus.ARRIVED, green[800]],
  [ShippingStatus.DEFAULT, green[800]],
])

export default ShippingStatus
