export default async (inputRows) => {
  const response = await fetch(`/api/smisupplychain/UploadData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(inputRows),
  }).then((x) => x.json())
  if (response.errorCode === 'FILE_PROCESSING_ERROR') {
    return response
  }
  if (response.errors) {
    throw new Error(
      `Failed to fetch: ${response.status} ${response.statusText} - ${response.errors}`,
    )
  }
  return response
}
