import { useMemo, useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { Button, Icon, Stack, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as XLSX from 'xlsx'
import uploadFile from '../dataAccess/uploadFile'
import { UploadContext } from '../contexts/UploadContext'

const SearchBar = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}))

type Props = {
  initialSKU?: string
  initialPO?: string
  intialContainer?: string
}

const SearchRow: React.FC<Props> = ({
  initialSKU,
  initialPO,
  intialContainer,
}: Props) => {
  const [skuID, setSkuID] = useState(initialSKU ?? '')
  const [poID, setPoID] = useState(initialPO ?? '')
  const [containerID, setContainerID] = useState(intialContainer ?? '')
  const router = useRouter()

  // TODO: make this more extendable lol
  const onEnterRoute = useMemo(
    () => `sku?sid=${skuID}&poid=${poID}&containerid=${containerID}`,
    [skuID, poID, containerID],
  )

  // TODO: Instead of onEnter, can have an OnClick for search bar
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // keycode for "enter" key
    if (e.key === 'Enter') {
      console.log('Calling Backend...')
      e.preventDefault()
      console.log(onEnterRoute)
      router.push(onEnterRoute)
    }
  }

  const { setUploadError } = useContext(UploadContext)

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    setUploadError(null, null)

    const reader = new FileReader()
    reader.onload = async (event) => {
      const data = event.target?.result
      if (typeof data !== 'string') {
        return
      }

      // convert Excel file to JSON
      const workbook = XLSX.read(data, { type: 'binary' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const json = XLSX.utils.sheet_to_json(worksheet)

      // Convert all numerical values to strings
      const jsonStringifiedNumbers = json.map((row: Record<string, any>) => {
        const newRow: Record<string, any> = {}
        for (const key in row) {
          newRow[key] =
            typeof row[key] === 'number' ? row[key].toString() : row[key]
        }
        return newRow
      })

      // Clean up specific fields
      const cleanedJson = jsonStringifiedNumbers.map(
        (row: Record<string, any>) => {
          const newRow: Record<string, any> = { ...row }
          if (newRow.__EMPTY) {
            newRow.__EMPTY = newRow.__EMPTY
              .replace(
                'Shipper/Exporter (Full Name, Address, and Phone Number)',
                '',
              )
              .replace('Consignee:Consignee:', '')
              .replace(/\r\n/g, '')
          }
          if (newRow.__EMPTY) {
            newRow.__EMPTY = newRow.__EMPTY
              .replace('Ship to Address:', '')
              .replace(/\r\n/g, '')
          }
          return newRow
        },
      )

      // send JSON data to backend
      try {
        const responseData = await uploadFile(cleanedJson)
        setUploadError('FILE_UPLOAD_SUCCESS')
        if (responseData.errorCode === 'FILE_PROCESSING_ERROR') {
          setUploadError(
            'FILE_UPLOAD_ERROR',
            responseData.errors.map((errorDetails) => (
              <div>
                <ul>
                  <li>{errorDetails}</li>
                </ul>
              </div>
            )),
          )
        }
      } catch (error) {
        setUploadError('FILE_UPLOAD_ERROR', error.json())
        throw error
      }
    }
    reader.readAsBinaryString(file)
  }

  const onChangeSKU = (e) => {
    setSkuID(e.target.value)
  }

  const onChangePO = (e) => {
    setPoID(e.target.value)
  }

  const onChangeContainerID = (e) => {
    setContainerID(e.target.value)
  }

  return (
    <Stack direction="row" spacing={4}>
      <SearchBar
        onChange={onChangeSKU}
        onKeyDown={onEnter}
        value={skuID}
        label="Enter an SKU"
        type="search"
        placeholder="Enter an SKU"
      />

      <SearchBar
        onChange={onChangePO}
        onKeyDown={onEnter}
        value={poID}
        label="Enter an PO"
        type="search"
        placeholder="Enter a PO"
      />

      <SearchBar
        onChange={onChangeContainerID}
        onKeyDown={onEnter}
        value={containerID}
        label="Enter a Container ID"
        type="search"
        placeholder="Enter a Container ID"
      />
      <Button variant="contained" component="label">
        Upload
        <input
          hidden
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="file"
          onChange={handleFileSelected}
        />
      </Button>
    </Stack>
  )
}

export default SearchRow
